import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./CustomLink.css";

export default function CustomLink({ icon = null, to, children, onClick, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    
    const scrollToTop = () => {
        if(onClick != null)
            onClick();

        window.scrollTo(0, 0);
    };

    return (
        <li className={isActive ? "active" : ""}>
            <Link onClick={scrollToTop} to={to} {...props}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                    {icon}
                    <p className="customLink">{children}</p>
                </div>
            </Link>
        </li>
    )
}