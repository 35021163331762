import './App.css';
import Navbar from './components/Navbar/Navbar';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import { AuthModal } from './components/AuthModal/AuthModal';
import Admin from './pages/Admin/Admin';
// import { useCookies } from 'react-cookie';
import { savedToken } from './utils/variables';
import Footer from './components/Footer/Footer';
import NotFound from './pages/NotFound/NotFound';
import { slide as Menu } from 'react-burger-menu';
import CustomLink from './components/CustomLink/CustomLink';
import { GrServices } from 'react-icons/gr';
import { PiHouseLine, PiIntersectDuotone } from 'react-icons/pi';
import Services from './pages/Services/Services';
import Sectors from './pages/Sectors/Sectors';
import { FiMail } from 'react-icons/fi';
import LegalNotice from './pages/LegalNotice/LegalNotice';
import DataProtectionPolicy from './pages/DataProtectionPolicy/DataProtectionPolicy';

function App() {
  let [visible, setVisible] = useState(false)
  let [menuVisible, setMenuVisible] = useState(false)
  let [adminMode, setAdminMode] = useState(false)
  // const [rememberCookie, setRememberCookie] = useCookies(['rememberToken']);
  const { t } = useTranslation('common');
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   if (rememberCookie.rememberToken != undefined) {
  //     setAdminMode(true)
  //     savedToken.value = rememberCookie.rememberToken;
  //   }
  // }, [t]);

  function toggleModalVisible() {
    if (visible)
      setVisible(false);
    else
      setVisible(true);
  }

  function handleMenuStateChange(state) {
    setMenuVisible(state.isOpen);
  };

  function closeMenu() {
    setMenuVisible(false);
  };

  function GetNavigation() {
    if (width <= 1100)
      return (
        <Menu onStateChange={handleMenuStateChange} isOpen={menuVisible} customCrossIcon={false}>
          <ul style={{ listStyleType: 'none' }}>
            <CustomLink onClick={closeMenu} icon={<PiHouseLine size={20} />} to="/">{t('header.home')}</CustomLink>
            <CustomLink onClick={closeMenu} icon={<GrServices size={20} />} to="/services">{t('header.services')}</CustomLink>
            <CustomLink onClick={closeMenu} icon={<PiIntersectDuotone size={20} />} to="/sectors">{t('header.sectors')}</CustomLink>
            <CustomLink onClick={closeMenu} icon={<FiMail size={20} />} to="/contact">{t('header.contact')}</CustomLink>
          </ul>
        </Menu>
      );
  }

  return (
    <React.Fragment>
      {/* {resetCookieConsentValue()} */}
      {GetNavigation()}
      <ToastContainer />
      <AuthModal setAdminMode={setAdminMode} toggleVisible={toggleModalVisible} visible={visible} />
      {/* <CookieConsent enableDeclineButton declineButtonText={t('cookies.declineButtonText')} buttonText={t('cookies.buttonText')} >{t('cookies.footerText')}</CookieConsent> */}
      <div className='container'>
        <Navbar adminMode={adminMode} onLockClick={toggleModalVisible} />
        <div className="body">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/sectors' element={<Sectors />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/legal-notice' element={<LegalNotice />} />
            <Route path='/data-protection-policy' element={<DataProtectionPolicy />} />
            {/* <Route path='/admin' element={<Admin adminMode={adminMode} />} /> */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default App;